import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { useLogsByOwner, useArticleOnce } from "../util/db";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  line: {
    color: theme.palette.divider
  }
}));

function jsDatetoUnixTimestamp(date) {
  // convert js date to unix timestamp
  return Math.floor(date.getTime() / 1000);
}

function urlToArticleID(url) {
  // convert url to article id by getting the utm_source get parameter
  const urlParams = new URLSearchParams(url);
  const articleID = urlParams.get("utm_source");
  return articleID;
}

function createTopURLsFromLogs(logs, range_str) {
  // filter logs based on range_str values, weekly should be last 7 days, monthly should be last 30 days, and compare the timestamps of the logs to the current date ranges
  const date_start = new Date();
  const date_end = new Date();
  if (range_str === "weekly") {
    date_start.setDate(date_start.getDate() - 7);
  } else if (range_str === "monthly") {
    date_start.setDate(date_start.getDate() - 30);
  }

  const filteredLogs = logs.filter(doc => {
    const timestamp = doc.timestamp;
    return (
      timestamp >= jsDatetoUnixTimestamp(date_start) && timestamp <= jsDatetoUnixTimestamp(date_end) &&
      doc.url !== ""
    );
  });

  const groupedDocs = {};
  filteredLogs.forEach(doc => {
    const url = doc.url;
    if (!groupedDocs[url]) {
      groupedDocs[url] = 1;
    } else {
      groupedDocs[url]++;
    }
  });

  const sortedDocs = Object.entries(groupedDocs).sort((a, b) => b[1] - a[1]);

  const topURLs = sortedDocs.slice(0, 5);
  return topURLs;
}

function ListArticlesBasedOnURL(props) {
  // Takes a property of an article list and returns a list with the url and the number of times it was opened
  const articleList = props.articleList;
  return (
    <List>
      {articleList.map((article, index) => (
        <ArticleListEntry id={index} url={article[0]} open_count={article[1]}/>
      ))}
    </List>
  );
}

function ArticleListEntry(props) {
  // Returns a ListItem Component with the article title and the number of times it was opened based on the url
  const url = props.url;
  const id = props.id;
  const open_count = props.open_count;

  const articleID = urlToArticleID(url);

  
  const {
      data: article,
      status: articleStatus,
      error: articleError,
    } = useArticleOnce(articleID);
  
  return (
    <ListItem key={id}>
      {(articleID === null || articleStatus === "loading" || articleError) && (
        <ListItemText
        primary={url}
        secondary={open_count}
        />
      )}
      { articleID && articleStatus !== "loading" && article && (
        <ListItemText
        primary={article.section + ": " + article.title}
        secondary={open_count}
        />
      )}      
    </ListItem>
  );
}

function TopArticlesList(props) {
  // Returns a component that displays the top 5 articles that were opened with a Box with FormControl and Select that switches between weekly and monthly 

  const classes = useStyles();
  const auth = useAuth();

  const [date_range, setValue] = React.useState("weekly");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const {
    data: logs,
    status: logsStatus,
    error: logsError,
  } = useLogsByOwner(auth.user.uid);

  return (
    <>
      {logsError && (
        <Box mb={3}>
          <Alert severity="error">{logsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h6" paragraph={true}>
            <strong>Top Clicked Articles</strong>
          </Typography>
          <FormControl className={classes.formControl}>
            <Select
              value={date_range}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
            </Select>
          </FormControl>
        </Box>

        {(logsStatus === "loading") && (
          <Box py={5} px={3} align="center">
            {logsStatus === "loading" && <CircularProgress size={32} />}
          </Box>
        )}
        {logsStatus !== "loading" && logs && (
          <Box py={5} px={3} align="center">
            <ListArticlesBasedOnURL articleList={createTopURLsFromLogs(logs, date_range)} />
          </Box>
        )}
      </Paper>
    </>
  );

}

export default TopArticlesList;
