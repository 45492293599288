import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { createDefaultNewsletterpref, useNewsletterpref, removeSectionFromPreference } from "../util/db";
import { Link } from "./../util/router";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function CurrentSectionsTable(props) {
  const classes = useStyles();

  const auth = useAuth();

  const {
    data: preference,
    status: preferenceStatus,
    error: preferenceError,
  } = useNewsletterpref(auth.user.uid);

  const no_preferences_set = !preference;

  return (
    <>
      {preferenceError && (
        <Box mb={3}>
          <Alert severity="error">{preferenceError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Enabled Sections</Typography>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            component={Link} to="/sections"
          >
            Add Sections
          </Button>
        </Box>
        <Divider />

        {(preferenceStatus === "loading" || no_preferences_set) && (
          <Box py={5} px={3} align="center">
            {preferenceStatus === "loading" && <CircularProgress size={32} />}

            {preferenceStatus !== "loading" && no_preferences_set  
            && createDefaultNewsletterpref(auth.user.uid) 
            && <CircularProgress size={32} />}
          </Box>
        )}

        {preferenceStatus !== "loading" && preference 
          && preference.sections 
          && preference.sections.length !== 0 
          && (
          <List disablePadding={true}>
            {preference.sections.map((section, index) => (
              <ListItem
                key={index}
                divider={index !== preference.sections.length - 1}
              >
                <ListItemText>{section.sectionreadable}</ListItemText>
                <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => removeSectionFromPreference(section, preference, auth.user.uid)}
                  >
                    <DeleteIcon />
                  </IconButton>
              </ListItem>
            ))}
          </List>
        )}
        {preferenceStatus !== "loading" && preference 
          && ( !preference.sections 
           || preference.sections.length === 0 )
          && (
          <List disablePadding={true}>
              <ListItem
                key="1"
              >
                <ListItemText>You haven't added any sections yet! Click the button below to start adding sections</ListItemText>
              </ListItem>
          </List>
        )}
      </Paper>
    </>
  );
}

export default CurrentSectionsTable;
