import React from "react";
import Meta from "../components/Meta";
import ContentSectionsSection from "../components/ContentSectionsSection";
import { requireAuth } from "../util/auth";

function ContentSectionsPage(props) {
  return (
    <>
      <Meta title="Subscribers" />
      <ContentSectionsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Choose Sections to Send to Your Audience"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(ContentSectionsPage);
