import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import NewsletterPreview from "./NewsletterPreview";
import CurrentSectionsTable from "./CurrentSectionsTable";


function NewsletterPrefsSection(props) {

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />

        <Grid container={true} spacing={4} >
          <Grid item={true} xs={12}>
            <CurrentSectionsTable />
          </Grid>
        </Grid>
        <Grid container={true} spacing={4} >
          <Grid item={true} xs={12}>
            <NewsletterPreview />
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default NewsletterPrefsSection;
