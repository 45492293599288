import { useQuery } from "react-query";
import { firebaseApp } from "./firebase";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// Initialize Firebase Storage
const storage = getStorage(firebaseApp);

// Fetch file from Firebase Storage
export function useFirebaseStorage(fileId) {
    const queryKey = ['firebaseStorage', fileId];
    const fetcher = async () => {
        const storageRef = ref(storage, fileId);
        const url = await getDownloadURL(storageRef);;
        const response = await fetch(url);
        const data = await response.text();
        return data;
    };
    return useQuery(queryKey, fetcher, {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    });
}