import React from "react";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import { Avatar } from "@material-ui/core";
import AlternateEmail from '@material-ui/icons/AlternateEmail';
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { useSubscribersByOwner } from "../util/db";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "200px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
}));

function SubscribersSummaryCard(props) {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();

  const {
    data: subscribers,
    status: subscribersStatus,
    error: subscribersError,
  } = useSubscribersByOwner(auth.user.uid);

  const handlePaperClick = () => {
    history.push('/subscribers');
  };

  return (
    <>
      {subscribersError && (
        <Box mb={3}>
          <Alert severity="error">{subscribersError.message}</Alert>
        </Box>
      )}

      
        <Paper className={classes.paperItems} onClick={handlePaperClick}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding={2}
          >
            <Typography variant="h6" paragraph={true}>
              <strong>Subscribers</strong>
            </Typography>
          </Box>
          <Divider />
          <List>
            <ListItem>

              <ListItemAvatar>
                <Avatar>
                  <AlternateEmail />
                </Avatar>
              </ListItemAvatar>
              {(subscribersStatus === "loading") && (
                <Box py={5} px={3} align="center">
                  {subscribersStatus === "loading" && <CircularProgress size={32} />}
                </Box>
              )}
              {subscribersStatus !== "loading" && subscribers && subscribers.length > 0 && (
                <ListItemText primary={"Current Subscribers: " + subscribers.length} />
              )}
            </ListItem>
          </List>
        </Paper>
    </>
  );
}

export default SubscribersSummaryCard;
