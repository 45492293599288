import React from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { useLogsByOwner } from "../util/db";

import ReactApexChart from 'react-apexcharts';

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
  line: {
    color: theme.palette.divider
  }
}));

function getDatesPastWeek() {
  const dates = [];
  const today = new Date();
  for (let i = 6; i >= 0; i--) {
    const date = new Date(today);
    date.setDate(today.getDate() - i);
    dates.push(date.toISOString().substr(0, 10));
  }
  return dates;
}

function generateChartData(groupedDocs, dates) {
  const chartData = [];

  let max = 0;
  for (const event in groupedDocs) {
    const eventDocs = groupedDocs[event];
    console.log(eventDocs)
    const data = Array(7).fill(0);

    for (const date in eventDocs) {
      const index = dates.indexOf(date);
      if (index !== -1) {
        data[index] = eventDocs[date];
        if (eventDocs[date] > max) {
          max = eventDocs[date];
        }
      }
    };

    chartData.push({ name: event, data });
  }

  return chartData;
}

function createSeriesFromLogs(logs) {
  const dates = getDatesPastWeek();
  const groupedDocs = {};
  logs.forEach(doc => {
    const event = doc.event;
    const date = new Date(doc.timestamp * 1000).toISOString().substr(0, 10);
    if (!groupedDocs[event]) {
      groupedDocs[event] = {};
    }
    if (!groupedDocs[event][date]) {
      groupedDocs[event][date] = 1;
    } else {
      groupedDocs[event][date]++;
    }
  });

  const chartSeries = generateChartData(groupedDocs, dates);
  return chartSeries

}

function createOptionsBasedOnDate() {
  const dates = getDatesPastWeek();
  return {
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false
      }
    },
    colors: ['#77B6EA', '#545454'],
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    markers: {
      size: 1
    },
    xaxis: {
      categories: dates,
      title: {
        text: 'Date'
      }
    },
    yaxis: {
      max: function (max) { return max + Math.floor(max * 0.2) },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5
    }
  }
}

function MailgunGraph(props) {
  const classes = useStyles();

  const auth = useAuth();

  const {
    data: logs,
    status: logsStatus,
    error: logsError,
  } = useLogsByOwner(auth.user.uid);

  const logsAreEmpty = !logs || logs.length === 0;

  let series = [];
  let options = {};
  if (!logsAreEmpty) {
    // map logs data to series format
    series = createSeriesFromLogs(logs);

    // create options based on date
    options = createOptionsBasedOnDate();
  }


  return (
    <>
      {logsError && (
        <Box mb={3}>
          <Alert severity="error">{logsError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h6" paragraph={true}>
            <strong>Newsletter Activity</strong>
          </Typography>
        </Box>

        {(logsStatus === "loading") && (
          <Box py={5} px={3} align="center">
            {logsStatus === "loading" && <CircularProgress size={32} />}
          </Box>
        )}

        {logsStatus !== "loading" && logs && (
          //<ReactApexChart options={options} series={series} type="area" height={450} />
          <ReactApexChart options={options} series={series} type="line" height={350} />
        )}
      </Paper>
    </>
  );
}

export default MailgunGraph;
