import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonGroup, IconButton, Typography } from "@material-ui/core";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useAuth } from "../util/auth.js";
import { useFirebaseStorage } from "../util/storage.js";
import { apiRequest } from "../util/util.js";



const useStyles = makeStyles((theme) => ({
  previewroot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonControls: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  previewtoggleButton: {

  },
  previewContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    width: "100%",
    height: "1000px",
    border: "none",
    boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.1)"
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: "none",
  },
}));

function getLastSunday(inputDate = null) {
  let today = inputDate
  if (today === null) {
    today = new Date();
  }
  const dayOfWeek = today.getDay();
  const daysSinceSunday = (dayOfWeek === 0 ? 7 : dayOfWeek) - 1;
  const lastSunday = new Date(today.getTime() - daysSinceSunday * 24 * 60 * 60 * 1000);
  return lastSunday;
}

function setNextSunday(inputDate, dateSetter) {
  const inputDatePlusSeven = new Date(inputDate.getTime() + 7 * 24 * 60 * 60 * 1000);
  dateSetter(getLastSunday(inputDatePlusSeven));
}

function setPreviousSunday(inputDate, dateSetter) {
  const inputDateMinusSeven = new Date(inputDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  dateSetter(getLastSunday(inputDateMinusSeven));
}

function NewsletterPreview(props) {
  const classes = useStyles();
  const auth = useAuth();
  const [isMobileView, setIsMobileView] = useState(false);
  const [newsletterDate, setNewsletterDate] = useState(getLastSunday());
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isErrror, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const localDateISOFormat = (date) => {
    const offset = date.getTimezoneOffset();
    const newDate = new Date(date.getTime() - (offset * 60 * 1000));
    return newDate.toISOString().split("T")[0];
  };

  // Construct file ID from user ID and date
  const fileId = `newsletters/${auth.user.id}/${localDateISOFormat(newsletterDate)}_newsletter.html`;

  // Fetch HTML content from Firebase Storage using React Query
  const {
    data: htmlContent,
    refetch: refetchNewsletter,
    status: htmlStatus,
    error: htmlError
  } = useFirebaseStorage(fileId);

  const handleToggleView = () => {
    setIsMobileView(!isMobileView);
  };

  const refreshNewsletter = () => {
    setIsRefreshing(true);
    apiRequest("buildnewsletter", "POST", { date: localDateISOFormat(newsletterDate) }).then((response) => {
      refetchNewsletter();
      console.log(response);
      setTimeout(() => {
        setIsRefreshing(false);
      }, 5000);
    }).catch((error) => {
      console.log(error);
      setErrorMessage(error.message);
      setIsError(true);
      setTimeout(() => {
        setIsRefreshing(false);
        setIsError(false);
        setErrorMessage("");
      }, 30000);
    });
  };

  const currentEddition = () => {
    const today = new Date();
    setNewsletterDate(getLastSunday(today));
  };

  return (
    <>
      {isErrror && (
        <Box mb={3}>
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      )}
      {htmlError && (
        <Box mb={3}>
          <Alert severity="error">Newsletter not generated for this date yet</Alert>
        </Box>
      )}
      <div className={classes.previewroot}>
        <Typography variant="h5" component="h1" gutterBottom>
          Newsletter Preview
        </Typography>
        <Typography variant="h7" component="h3" gutterBottom>
          {newsletterDate.toDateString()}
        </Typography>
        <ButtonGroup>
          <IconButton aria-label="previous"
            variant="contained"
            color="primary"
            onClick={() => { setPreviousSunday(newsletterDate, setNewsletterDate) }}
            disabled={isRefreshing} // Disable the button when isRefreshing is true
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Button
            className={classes.previewtoggleButton}
            variant="contained"
            onClick={handleToggleView}
          >
            {isMobileView ? "Mobile View" : "Desktop View"}
          </Button>
          <Button
            className={classes.previewtoggleButton}
            variant="contained"
            onClick={currentEddition}
            disabled={isRefreshing} // Disable the button when isRefreshing is true
          >
            Current Edition
          </Button>
          <Button
            className={classes.previewtoggleButton}
            variant="contained"
            onClick={refreshNewsletter}
            disabled={isRefreshing} // Disable the button when isRefreshing is true
          >
            {isRefreshing ? "Refreshing..." : "Refresh Newsletter"}
          </Button>
          <IconButton aria-label="previous"
            variant="contained"
            color="primary"
            onClick={() => { setNextSunday(newsletterDate, setNewsletterDate) }}
            disabled={isRefreshing} // Disable the button when isRefreshing is true
          >
            <KeyboardArrowRight />
          </IconButton>
        </ButtonGroup>
        {(htmlStatus === "loading" && !htmlError) && (
          <Box py={5} px={3} align="center">
            <CircularProgress size={32} />
          </Box>
        )}
        {htmlStatus !== "loading" && htmlContent && (
          <div className={classes.previewContainer}
            style={{ width: isMobileView ? "320px" : "100%" }}>
            <iframe
              title="Newsletter Preview"
              className={classes.iframe}
              srcDoc={htmlContent}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default NewsletterPreview;