import React from "react";
import Meta from "../components/Meta";
import SubscribersSection from "../components/SubscribersSection"
import { requireAuth } from "../util/auth";

function SubscribersPage(props) {
  return (
    <>
      <Meta title="Subscribers" />
      <SubscribersSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Current Subscribers"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(SubscribersPage);
