import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";

import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import EditSubscriberModal from "./EditSubscriberModal";
import { useAuth } from "../util/auth";
import { deleteSubscriber, useSubscribersByOwner, createSubscriber } from "../util/db";
import Papa from "papaparse";
import { ButtonGroup } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },
}));

function parseCSVText(text) {
  // Map variations of field names to standard names
  const fieldMap = {
    email: ["email", "emailaddress", "mail", "e_mail", "electronicmail", "user_email", "client_email", "contact_email", "e-mail", "e mail"],
    firstName: ["firstname", "first_name", "givenname", "fName", "forename", "first", "f_name", "given name", "first name"],
    lastName: ["lastname", "last_name", "familyname", "lName", "surname", "last", "s_name", "family name", "last name"],
  };

  // Remove duplicates
  for (const key in fieldMap) {
    const values = [...new Set(fieldMap[key])];
    fieldMap[key] = values.map(value => value.toLowerCase());
  }

  // Parse CSV data using papaparse: https://www.papaparse.com/
  const parsed = Papa.parse(text, { header: true });
  if (parsed.errors.length > 0) {
    throw new Error("Error parsing CSV data");
  }

  const data = parsed.data.map(entry => {
    const mappedEntry = {};
    let mandatory_fields = ["email", "firstName", "lastName"]
    for (const key in entry) {
      const lowercaseKey = key.toLowerCase(); // convert key to lowercase
      console.log("checking key: " + lowercaseKey + "")
      if (fieldMap.email.includes(lowercaseKey)) {
        mappedEntry.email = entry[key];
        mandatory_fields = mandatory_fields.filter(e => e !== "email")
      } else if (fieldMap.firstName.includes(lowercaseKey)) {
        mappedEntry.firstName = entry[key];
        mandatory_fields = mandatory_fields.filter(e => e !== "firstName")
      } else if (fieldMap.lastName.includes(lowercaseKey)) {
        mappedEntry.lastName = entry[key];
        mandatory_fields = mandatory_fields.filter(e => e !== "lastName")
      } else {
        mappedEntry[key] = entry[key];
      }
    }
    if (mandatory_fields.length > 0) {
      throw new Error("CSV data is missing the following required fields: " + mandatory_fields.join(", "));
    }
    return mappedEntry;
  });

  return data;
}

function SubscribersTable(props) {
  const classes = useStyles();
  const auth = useAuth();

  const {
    data: subscribers,
    status: subscribersStatus,
    error: subscribersError,
  } = useSubscribersByOwner(auth.user.uid);

  const [creatingSubscriber, setCreatingSubscriber] = useState(false);
  const [updatingSubscriberId, setUpdatingSubscriberId] = useState(null);
  const subscribersAreEmpty = !subscribers || subscribers.length === 0;
  const [formAlert, setFormAlert] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event) => {
      const csvData = event.target.result;
      try {
        const entries = parseCSVText(csvData);
        entries.map((entry) => {
          createSubscriber({ owner: auth.user.uid, ...entry }).then(
            console.log("Subscriber created")
          ).catch((error) => {
            setFormAlert({
              type: "error",
              message: error.message,
            })
          });
          return null;
        });
        setFormAlert({ type: "success", message: "Successfully Uploaded Contacts" });
      } catch (error) {
        setFormAlert({ type: "error", message: error.message })
      }
    };
    // Reset file input element
    event.target.value = null;
    setFormAlert({ type: "success", message: "Successfully Uploaded Contacts" })
  };



  return (
    <>
      {subscribersError && (
        <Box mb={3}>
          <Alert severity="error">{subscribersError.message}</Alert>
        </Box>
      )}

      {formAlert && (
        <Box mb={4}>
          <Alert severity={formAlert.type} onClose={() => { setFormAlert(null) }}>{formAlert.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Subscribers</Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="right"
          padding={2}
        >
          <ButtonGroup>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => setCreatingSubscriber(true)}
            >
              Add Subscriber
            </Button>
            <Button
              variant="contained"
              size="medium"
              color="primary"
              component="label"
            >
              Upload CSV
              <input type="file" hidden onChange={handleFileUpload} />
            </Button>
          </ButtonGroup>
        </Box>
        <Divider />

        {(subscribersStatus === "loading" || subscribersAreEmpty) && (
          <Box py={5} px={3} align="center">
            {subscribersStatus === "loading" && <CircularProgress size={32} />}

            {subscribersStatus !== "loading" && subscribersAreEmpty && (
              <>Nothing yet. Click the button to add your first subscriber.</>
            )}
          </Box>
        )}

        {subscribersStatus !== "loading" && subscribers && subscribers.length > 0 && (
          <SubscriberDataTable subscribers={subscribers} setUpdatingSubscriberId={setUpdatingSubscriberId} deleteSubscriber={deleteSubscriber} />
        )}
      </Paper>

      {creatingSubscriber && <EditSubscriberModal onDone={() => setCreatingSubscriber(false)} />}

      {updatingSubscriberId && (
        <EditSubscriberModal
          id={updatingSubscriberId}
          onDone={() => setUpdatingSubscriberId(null)}
        />
      )}
    </>
  );
}

function SubscriberDataTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");

  const subscribers = props.subscribers;
  // Helper functions
  const setUpdatingSubscriberId = props.setUpdatingSubscriberId;
  const deleteSubscriber = props.deleteSubscriber;

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrderBy(property);
    setOrder(isAsc ? "desc" : "asc");
  };

  const sortedSubscribers = subscribers.sort((a, b) => {
    const aValue = orderBy === "email" ? a.email.toLowerCase() : `${a.firstName} ${a.lastName}`.toLowerCase();
    const bValue = orderBy === "email" ? b.email.toLowerCase() : `${b.firstName} ${b.lastName}`.toLowerCase();
    if (aValue < bValue) {
      return order === "asc" ? -1 : 1;
    }
    if (aValue > bValue) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell onClick={() => handleSort("name")} style={{ cursor: "pointer" }}>
              Name {orderBy === "name" && <span>{order === "asc" ? "▲" : "▼"}</span>}
            </TableCell>
            <TableCell onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
              Email {orderBy === "email" && <span>{order === "asc" ? "▲" : "▼"}</span>}
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedSubscribers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((subscriber, index) => (
            <TableRow
              key={index}
              hover
              className={subscriber.featured ? classes.featured : ""}
            >
              <TableCell>{`${subscriber.firstName} ${subscriber.lastName}`}</TableCell>
              <TableCell>{subscriber.email}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="update"
                  onClick={() => setUpdatingSubscriberId(subscriber.id)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => deleteSubscriber(subscriber.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={subscribers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
};

export default SubscribersTable;
