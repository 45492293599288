import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from "../util/auth";
import { createDefaultNewsletterpref, useNewsletterpref, addSectionToPreference, removeSectionFromPreference } from "../util/db";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Card, CardActions, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  paperItems: {
    minHeight: "300px",
  },
  featured: {
    backgroundColor:
      theme.palette.type === "dark" ? theme.palette.action.selected : "#fdf8c2",
  },
  starFeatured: {
    color: theme.palette.warning.main,
  },

  sectioncardbox: {
    display: "flex",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

function SectionCard(props) {
  const auth = useAuth();
  const classes = useStyles();

  var disabled=true;
  props.preference.sections.forEach(element => {
    if( element.id === props.section.id) {
      disabled=false;
    }
  })

  const [sectionDisabled, setSectionDisabled] = useState(disabled);


  return (
    <Box className={classes.sectioncardbox}>
      <Card 
        variant="outlined" 
        className={sectionDisabled ? "" : classes.featured}
        onClick={() => {
          if(sectionDisabled) {
            addSectionToPreference(props.section, props.preference, auth.user.uid)
          } else {
            removeSectionFromPreference(props.section, props.preference, auth.user.uid)
          }
          setSectionDisabled(!sectionDisabled);
          
        }}> 
        <CardContent>
        <Typography variant="h6">{props.section.sectionreadable}</Typography>
        <Typography variant="body1">{props.section.shortdescription}</Typography>
        </CardContent>
        <CardActions>
          { sectionDisabled && (
              <Button>Enable</Button>
          )}
          { !sectionDisabled && (
              <Button>Disable</Button>
          )}
          
        </CardActions>
      </Card>
    </Box>

  )
}

function SectionGrid(props) {
  const classes = useStyles();

  const auth = useAuth();

  const sections = props.sections;
  

  const {
    data: preference,
    status: preferenceStatus,
    error: preferenceError,
  } = useNewsletterpref(auth.user.uid);

  const no_preferences_set = !preference;

  return (
    <>
      {preferenceError && (
        <Box mb={3}>
          <Alert severity="error">{preferenceError.message}</Alert>
        </Box>
      )}

      <Paper className={classes.paperItems}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding={2}
        >
          <Typography variant="h5">Available Sections</Typography>
  
        </Box>
        <Divider />

        {(preferenceStatus === "loading" || no_preferences_set) && (
          <Box py={5} px={3} align="center">
            {preferenceStatus === "loading" && <CircularProgress size={32} />}

            {preferenceStatus !== "loading" && no_preferences_set  
            && createDefaultNewsletterpref(auth.user.uid) 
            && <CircularProgress size={32} />}
          </Box>
        )}

        {preferenceStatus !== "loading" && preference 
          && preference.sections  
          && (
            <Grid container={true} spacing={2}>
            {sections.map((section, index) => (
              <Grid item={true} xs={4} key={index}>
                <SectionCard
                  section={section}
                  preference={preference}
                  />
              </Grid>
            ))}
          </Grid>
        )}

        
        
        
      </Paper>
    </>
  );
}

export default SectionGrid;
