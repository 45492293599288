import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Box from "@material-ui/core/Box";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";
import {useContentSections}  from "../util/db"
import SectionGrid from "./SectionGrid";

function ContentSectionsSection(props) {

  // Grab all the Existing Sections

  const {
    data: sections,
    status: sectionsStatus,
    error: sectionsError,
  } = useContentSections();

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
      
        {sectionsError && (
          <Box mb={3}>
            <Alert severity="error">{sectionsError.message}</Alert>
          </Box>
        )}

        {(sectionsStatus === "loading" || !sections ) && (
          <Box py={5} px={3} align="center">
            {sectionsStatus === "loading" && <CircularProgress size={32} />}

            {sectionsStatus !== "loading" && !sections && (
              <>Unable to load sections. This shouldn't have happened</>
            )}
          </Box>
        )}

      { sectionsStatus !== "loading" && sections && (
      <Grid container={true} spacing={4}>
        <Grid item={true} xs={12}>
            <SectionGrid
              sections={sections} />
        </Grid>
      </Grid>
      )
      }
      </Container>
    </Section>
  );
}

export default ContentSectionsSection;
