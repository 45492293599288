import React from "react";
import Meta from "../components/Meta";
import NewsletterPrefsSection from "../components/NewsletterPrefsSection";
import { requireAuth } from "../util/auth";

function PreferencesPage(props) {
  return (
    <>
      <Meta title="Newsletter Preferences" />
      <NewsletterPrefsSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Customize Your Newsletter"
        subtitle=""
      />
    </>
  );
}

export default requireAuth(PreferencesPage);
